@media screen and (max-width: 1440px) {
    .middle-wrapper {
        min-height: calc(100vh - 50px);
        padding: 25px;
    }
    .adventure-section h6 {
        font-size: 40px;
        margin-bottom: 40px;
    }
    .adventure-section {
        margin-top: 60px;
    }
}
@media screen and (max-width: 1240px) {
    .middle-wrapper {
        min-height: calc(100vh - 50px);
        padding: 25px;
    }
    .adventure-section h6 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .adventure-section {
        margin-top: 40px;
    }
    .logo-section img {
        height: 150px;
    }
    .notify-btn button, .notify-btn button:hover, .notify-btn button:focus {
        padding: 8px 40px;
        margin-top: 30px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .logo-section img {
        height: 100px;
    }
    .logo-section h6 {
        font-size: 18px;
    }
    .social-icon img {
        height: 25px !important;
        margin: 5px 5px;
    }
    .adventure-section h6 {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .notify-btn button, .notify-btn button:hover, .notify-btn button:focus {
        padding: 8px 30px;
        margin-top: 30px;
        font-size: 12px;
    }
    .footer p {
        color: white;
        font-size: 10px;
    }
}
@media screen and (max-width: 991px) {
    .logo-section {
        display: block;
        text-align: center;
    }
    .logo-section img {
        height: 80px;
        margin: 20px 0px;
    }
    .logo-section h6 {
        font-size: 18px;
    }
    .social-icon img {
        height: 25px !important;
        margin: 5px 5px;
    }
    .adventure-section h6 {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .notify-btn button, .notify-btn button:hover, .notify-btn button:focus {
        padding: 8px 30px;
        margin-top: 30px;
        font-size: 12px;
    }
    .footer p {
        color: white;
        font-size: 10px;
    }
    .middle-wrapper{
        width: 100% !important;
        padding: 20px 0px;
    }
    .footer{
        width: 100%;
        padding: 0px ;
    }
}