.middle-wrapper {
  min-height: calc(100vh - 50px);
  width: 85%;
  margin: 0px auto;
}
.news-letter-page {
  background: url("../../assets/bg-new.jpg");
  background-attachment: fixed;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.news-letter-page:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: #000000ad;
}

.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 999;
  padding-top: 20px;
}

.logo-section h6 {
  font-size: 22px;
  color: white;
  font-weight: 400;
}

.social-icon img {
  height: 40px !important;
  margin: 5px 8px;
  cursor: pointer;
}
.social-icon a{
  text-decoration: none;
}
.logo-section img {
  height: 185px;
}
.adventure-section {
  text-align: center;
  margin-top: 80px;
  position: relative;
  z-index: 999;
}

.adventure-section button img, .adventure-section a img {
  height: 30px;
  margin-left: 10px;
}

.adventure-section h6 {
  color: white;
  font-size: 45px;
  text-transform: uppercase;
  margin-bottom: 50px;
  font-weight: 500;
}
.notify-btn button,
.notify-btn button:hover,
.notify-btn button:focus,
.notify-btn a,
.notify-btn a:hover,
.notify-btn a:focus
{
  background: transparent;
  min-width: 260px;
  border-radius: 35px;
  padding: 10px 40px;
  margin-top: 40px;
  border: 3px solid white;
  /* outline: 5px solid #1052e6; */
  box-shadow: -1px 0px 10px 3px #1a1aab, inset 0px 1px 10px 2px #1a1aab;
  font-weight: 700;
  font-size: 18px;
}

.middle-wrapper {
  min-height: calc(100vh - 50px);
  padding: 50px;
}

.footer {
  text-align: left;
  position: relative;
  z-index: 9999;
  padding: 0px 70px;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: end;
  width: 85%;
  margin: 0px auto;
}

.footer p {
  color: white;
  font-size: 14px;
}
.social-icon a:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}